import jpg from './group.jpg'
import webp from './group.webp'

const group = {
  jpg: jpg,
  webp: webp,
  alt: '둘러앉아 얘기하는 사람ㄷ믈',
}

export default group
