import sunset_2400w from './sunset_2400w.jpg'
import sunset_1920w from './sunset_1920w.jpg'
import sunset_1280w from './sunset_1280w.jpg'
import sunset_500w from './sunset_500w.jpg'

const sunset = {
  src2400: sunset_2400w,
  src1920: sunset_1920w,
  src1280: sunset_1280w,
  src500: sunset_500w,
}

export default sunset
