export const CREATE_NOTICE_REQUEST = 'CREATE_NOTICE_REQUEST'
export const CREATE_NOTICE_SUCCESS = 'CREATE_NOTICE_SUCCESS'
export const CREATE_NOTICE_FAIL = 'CREATE_NOTICE_FAIL'

export const FETCH_NOTICES_REQUEST = 'FETCH_NOTICES_REQUEST'
export const FETCH_NOTICES_SUCCESS = 'FETCH_NOTICES_SUCCESS'
export const FETCH_NOTICES_FAIL = 'FETCH_NOTICES_FAIL'
export const FETCH_NOTICES_RESET = 'FETCH_NOTICES_RESET'

export const FETCH_NOTICE_REQUEST = 'FETCH_NOTICE_REQUEST'
export const FETCH_NOTICE_SUCCESS = 'FETCH_NOTICE_SUCCESS'
export const FETCH_NOTICE_FAIL = 'FETCH_NOTICE_FAIL'

export const UPDATE_NOTICE_REQUEST = 'UPDATE_NOTICE_REQUEST'
export const UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE_SUCCESS'
export const UPDATE_NOTICE_FAIL = 'UPDATE_NOTICE_FAIL'

export const DELETE_NOTICE_REQUEST = 'DELETE_NOTICE_REQUEST'
export const DELETE_NOTICE_SUCCESS = 'DELETE_NOTICE_SUCCESS'
export const DELETE_NOTICE_FAIL = 'DELETE_NOTICE_FAIL'
