import feature1_jpg from './feature1.jpg'
import feature1_webp from './feature1.webp'
import feature2_jpg from './feature2.jpg'
import feature2_webp from './feature2.webp'
import feature3_jpg from './feature3.jpg'
import feature3_webp from './feature3.webp'

const features = [
  {
    webp: feature1_webp,
    jpg: feature1_jpg,
    alt: '빙산',
  },
  {
    webp: feature2_webp,
    jpg: feature2_jpg,
    alt: '달력',
  },
  {
    webp: feature3_webp,
    jpg: feature3_jpg,
    alt: '아기 걸음마',
  },
]

export default features
