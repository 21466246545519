import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './utils/store'
import App from './App'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
