import forest_1920w from './forest_1920w.jpg'
import forest_1280w from './forest_1280w.jpg'
import forest_640w from './forest_640w.jpg'

const forest = {
  src1920: forest_1920w,
  src1280: forest_1280w,
  src640: forest_640w,
}

export default forest
