import symptom1_jpg from './symptom1.jpg'
import symptom1_webp from './symptom1.webp'
import symptom2_jpg from './symptom2.jpg'
import symptom2_webp from './symptom2.webp'
import symptom3_jpg from './symptom3.jpg'
import symptom3_webp from './symptom3.webp'
import symptom4_jpg from './symptom4.jpg'
import symptom4_webp from './symptom4.webp'

const symptoms = [
  {
    webp: symptom1_webp,
    jpg: symptom1_jpg,
    alt: '좌절한 사람',
  },
  {
    webp: symptom2_webp,
    jpg: symptom2_jpg,
    alt: '어질러진 침대',
  },
  {
    webp: symptom3_webp,
    jpg: symptom3_jpg,
    alt: '감정이 그려진 계란',
  },
  {
    webp: symptom4_webp,
    jpg: symptom4_jpg,
    alt: '아파하는 사람',
  },
]

export default symptoms
