import sunlight_1920w from './sunlight_1920w.jpg'
import sunlight_1280w from './sunlight_1280w.jpg'
import sunlight_640w from './sunlight_640w.jpg'

const sunlight = {
  src1920: sunlight_1920w,
  src1280: sunlight_1280w,
  src640: sunlight_640w,
}

export default sunlight
