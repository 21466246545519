import interior1_jpg from './interior1.jpg'
import interior1_webp from './interior1.webp'
import interior2_jpg from './interior2.jpg'
import interior2_webp from './interior2.webp'
import interior3_jpg from './interior3.jpg'
import interior3_webp from './interior3.webp'
import interior4_jpg from './interior4.jpg'
import interior4_webp from './interior4.webp'

const interiors = [
  {
    webp: interior1_webp,
    jpg: interior1_jpg,
    alt: '내부 모습',
    title:
      '입구로 들어오면 만나게 되는 온빛의 내부 모습입니다. <br/> 사진 왼편에 면담실이 자리하고 있습니다.',
  },
  {
    webp: interior2_webp,
    jpg: interior2_jpg,
    alt: '휴게 공간',
    title:
      '자유롭게 이용하실 수 있는 휴게 공간을 마련했습니다. <br/> 편안하게 대기 및 휴식하실 수 있습니다.',
  },
  {
    webp: interior3_webp,
    jpg: interior3_jpg,
    alt: '휴게 공간',
    title:
      '안쪽에도 마찬가지로 휴게 공간이 있습니다. <br /> 책장에 있는 도서는 대여하실 수 있습니다.',
  },
  {
    webp: interior4_webp,
    jpg: interior4_jpg,
    alt: '면담실',
    title: '면담을 하는 면담실입니다.',
  },
]

export default interiors
