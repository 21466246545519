import profile_jpg from './profile.jpg'
import profile_webp from './profile.webp'

const profile = {
  webp: profile_webp,
  jpg: profile_jpg,
  alt: '박노연 대표원장',
}

export default profile
