import jpg from './personal.jpg'
import webp from './personal.webp'

const personal = {
  jpg: jpg,
  webp: webp,
  alt: '테이블과 의자 두개',
}

export default personal
